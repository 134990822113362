import numeral from 'numeral';
import moment from 'moment';
import { I18N } from 'aurelia-i18n';
import { IPrice } from 'domain/Product/IPrice';

/* STOCK */

export class StockNameValueConverter {
  toView(stock: string | undefined, i18n: I18N) {
    if (stock == "e") {
      return i18n.tr('components.stock.type.many');
    } else if (stock == "t") {
      return i18n.tr('components.stock.type.special-order');
    } else if (stock == "l") {
      return i18n.tr('components.stock.type.not-coming');
    }

    return stock + " " + i18n.tr('components.stock.type.pcs');
  }
}

export class ObjectToStockValueConverter {
  toView(obj: object | undefined, i18n: I18N) {
    let result = "";

    if (obj === undefined || obj === null)
      return result;

    for (const [key, value] of Object.entries(obj)) {
      const name = (new StockNameValueConverter()).toView(value, i18n);
      result += `${key}: ${name}<br>`;
    }

    return result;
  }
}

/* PRICE */

export class CurrencyFormatValueConverter {
  toView(value?: number) {
    if (value == null) {
      return "? €";
    }
    return numeral(value).format('0,0.00 $');
  }
}

export class PricesToTooltipValueConverter {
  toView(prices: IPrice[] | undefined, i18n: I18N, ) {
    let result = "";

    if (!prices)
      return result;

    prices.forEach(price => {
      if (price.withoutVat == null) {
        result += `${price.name}<br>`;
      } else {
        const amount = (new CurrencyFormatValueConverter()).toView(price.displayAmount);
        const vatType = price.displayAmount == price.withoutVat ? 'without' : 'with';
        result += `${price.name}: ${amount} ${i18n.tr('components.price.type.vat.' + vatType)}<br>`;
      }
    });

    return result;
  }
}

/* OTHER */

export class DatetimeFormatValueConverter {
  toView(value?: string) {
    return moment(value).format("DD.MM.YYYY HH:mm");
  }
}

export class DateFormatValueConverter {
  toView(value?: string) {
    return moment(value).format("DD.MM.YYYY");
  }
}

export class PrintOrQuestionmarkValueConverter {
  toView(value?: string) {
    return value ? value : "?";
  }
}

// A ValueConverter for iterating an Object's properties inside of a repeat.for in Aurelia
// (c) https://ilikekillnerds.com/2015/08/iterating-objects-using-repeat-for-in-aurelia/
export class ObjectKeysValueConverter {
  toView(obj: object) {
    // Create a temporary array to populate with object keys
    const temp = [];

    // A basic for..in loop to get object properties
    // https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Statements/for...in
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        temp.push(prop);
      }
    }

    return temp;
  }
}
