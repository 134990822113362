export enum PriceType {
  Unknown = 0,
  Discount = 1,
  Wholesale = 2,
  Retail = 3,
  AmountDiscount = 4,
  TimeDiscount = 5,
  UserGroupTimeDiscount = 6,
  AmountDiscountList = 7
}
