/* eslint-disable @typescript-eslint/no-var-requires */
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { I18N } from "aurelia-i18n";
import { ServiceResponseError } from "utils/classes/error/ServiceResponseError";
import { IProduct } from "domain/Product/IProduct";
import { Modal, Toast } from "bootstrap";
import { IParentItem } from "domain/Base/IParentItem";
import { IStockItem } from "domain/Product/IStockItem";

export const log = LogManager.getLogger("app.utils.helpers.utils");

export class Utils {
  public static getErrorMessage(error: Error, i18n: I18N): string {
    if (!(error instanceof ServiceResponseError)) {
      log.error(error.name, error);
      return i18n.tr("general.errors.unknown", { error: error.name });
    }

    const status = error.response.status;
    if (status == 401) return i18n.tr("general.errors.session-expired");

    return i18n.tr("general.errors.backend", { status: status });
  }

  public static loadSortable() {
    const $ = require("jquery");
    require("moment");
    require("bootstrap-sortable");
    require("/node_modules/bootstrap-icons/font/bootstrap-icons.css");

    $(() => {
      ($ as any).bootstrapSortable();
      const bsIconsClasses = ".bi-filter, .bi-sort-down, .bi-sort-up";

      // Automatically add the sorting icons (and remove old ones)
      $('th[data-defaultsign="nospan"]').children(bsIconsClasses).remove();
      $('th[data-defaultsign="nospan"]').append(' <i class="bi-filter"></i>');

      $(".sortable").on("sorted", function () {
        // Update the sort icons
        $(this)
          .find('th[data-defaultsign="nospan"]')
          .each(function () {
            const icon = $(this).find(bsIconsClasses);
            icon.removeClass("bi-filter bi-sort-down bi-sort-up");

            if ($(this).hasClass("up")) {
              icon.addClass("bi-sort-up");
            } else if ($(this).hasClass("down")) {
              icon.addClass("bi-sort-down");
            } else if ($(this).hasClass("nosort")) {
              icon.addClass("bi-filter");
            }
          });
      });
    });
  }

  public static checkPasswordValidity(i18n: I18N) {
    const $ = require("jquery");
    $(() => {
      const np = $("#new-password")[0] as HTMLInputElement;
      const npa = $("#new-password-again")[0] as HTMLInputElement;

      const capitalcheck = /^(?=.*[A-Z]).*$/;
      const lowercasecheck = /^(?=.*[a-z])(?!.*\s).*$/;
      const numbercheck = /^(?=.*\d)(?!.*\s).*$/;

      if (np.value.length < 8) {
        np.setCustomValidity(i18n.tr("pages.user.settings.sections.password.errors.too-short"));
        return;
      }
      if (!lowercasecheck.test(np.value)) {
        np.setCustomValidity(i18n.tr("pages.user.settings.sections.password.errors.no-lowercase"));
        return;
      }
      if (!numbercheck.test(np.value)) {
        np.setCustomValidity(i18n.tr("pages.user.settings.sections.password.errors.no-number"));
        return;
      }
      if (!capitalcheck.test(np.value)) {
        np.setCustomValidity(i18n.tr("pages.user.settings.sections.password.errors.no-uppercase"));
        return;
      }
      if (npa.value != np.value) {
        np.setCustomValidity("");
        npa.setCustomValidity(i18n.tr("pages.user.settings.sections.password.errors.no-match"));
        return;
      }

      np.setCustomValidity("");
      npa.setCustomValidity("");
    });
  }

  public static showSuccessToast(message: string) {
    const successToastElement = document.querySelector(".success-toast")!;
    const $ = require("jquery");

    $(".success-toast .toast-body").html(message);
    new Toast(successToastElement).show();
  }

  public static showInfoToast(message: string) {
    const infoToastElement = document.querySelector(".info-toast")!;
    const $ = require("jquery");

    $(".info-toast .toast-body").html(message);
    new Toast(infoToastElement).show();
  }

  public static showErrorToast(log: Logger, message: string) {
    const errorToastElement = document.querySelector(".error-toast")!;
    const $ = require("jquery");

    if (message == "") return;

    log.error(message);

    $(".error-toast .toast-body").html(message);
    new Toast(errorToastElement).show();
  }

  // (c) https://www.codegrepper.com/code-examples/delphi/typescript+array+duplicate+remove
  public static unique(array: any[]): any[] {
    return array.filter((v, i) => array.indexOf(v) === i);
  }

  // (c) https://stackoverflow.com/a/35476997
  public static removeEmpty(array: string[]): string[] {
    return array.filter(entry => entry.trim() != "");
  }

  public static isArrayEmpty(array: any): boolean {
    return this.isEmpty(array) || array.length == 0;
  }
  public static isObjectEmpty(object: any): boolean {
    return this.isEmpty(object) || Object.keys(object).length == 0;
  }
  public static isEmpty(object: any): boolean {
    return object === undefined || object === null;
  }
  public static isPositive(object: string | number | null | undefined): boolean {
    return !this.isEmpty(object) && +(object as string | number) > 0;
  }

  public static round(num: number, i = 2): number {
    return +(Math.round(`${num}e+${i}` as any) + `e-${i}`);
  }

  // (c) https://stackoverflow.com/a/8189268
  public static chunkify(array: any[], n: number, balanced: boolean): any[] {
    if (n < 2) return [array];

    const len = array.length;
    const out = [];
    let i = 0;
    let size;

    if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
        out.push(array.slice(i, (i += size)));
      }
    } else if (balanced) {
      while (i < len) {
        size = Math.ceil((len - i) / n--);
        out.push(array.slice(i, (i += size)));
      }
    } else {
      n--;
      size = Math.floor(len / n);

      if (len % size === 0) size--;

      while (i < size * n) {
        out.push(array.slice(i, (i += size)));
      }

      out.push(array.slice(size * n));
    }

    return out;
  }

  public static setLocale(eventAggregator: EventAggregator, i18n: I18N, locale: string) {
    try {
      i18n.setLocale(locale);
      eventAggregator.publish(TKEvent.localeChanged, locale);
    } catch (_) {
      location.reload();
    }
  }

  public static getLocaleClass(locale: string): string {
    switch (locale) {
      case "et_EE":
        return "flag-icon-ee";
      case "en_GB":
        return "flag-icon-gb";
      case "ru_RU":
        return "flag-icon-ru";
      case "fi_FI":
        return "flag-icon-fi";
      default:
        return "";
    }
  }

  // In some situations special characters in search bars may not display properly:
  // https://github.com/aurelia/router/issues/631#issuecomment-761773471
  public static encode(string: any, optionalParameter = false) {
    if (optionalParameter) return encodeURIComponent(string); // Only encode optional parameters once, thanks Aurelia
    return encodeURIComponent(encodeURI(string));
  }

  public static showGallery(product: IProduct) {
    const $ = require("jquery");
    require("bootstrap");

    $(() => {
      // If there are no images to display
      if ($(`[data-gallery="${product.id}"]`).children(".carousel-inner").children().length == 0)
        return;

      const galleryImage = document.querySelector(`[data-gallery-wrapper="${product.id}"]`);
      if (galleryImage) new Modal(galleryImage).show();
    });
  }

  public static escapeRegex(string: string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  public static nameMatches(item: IParentItem, filter: string): boolean {
    return item.name.length < filter.length ? false : item.name.toLowerCase().indexOf(filter) > -1;
  }

  public static deepFilterItems(items: IParentItem[], filter: string): IParentItem[] {
    const filtered: IParentItem[] = [];

    for (const item of items) {
      const hasChildren = item.children && item.children.length > 0;
      const newItem = Object.assign({}, item);

      if (hasChildren) newItem.children = this.deepFilterItems(item.children, filter);

      if ((hasChildren && newItem.children.length > 0) || this.nameMatches(item, filter))
        filtered.push(newItem);
    }

    return filtered;
  }

  public static getFilterQuery(filters: object): string {
    let query = "";

    let i = 0;
    // eslint-disable-next-line prefer-const
    for (let [filter, values] of Object.entries(filters)) {
      if (!values || values.length == 0) continue;

      filter = Utils.encode(filter, true);
      query += `filters[${i}].key=${filter}&`;

      let j = 0;
      for (let value of values) {
        value = Utils.encode(value, true);
        query += `filters[${i}].value[${j}]=${value}&`;

        j++;
      }

      i++;
    }

    // Remove trailing '&'
    return query.slice(0, -1);
  }

  public static isValidTireString(tire: string): boolean {
    return /^.{0,3}(\d{3})\/?(\d{2})?\D{0,3}(\d{2})\D/.test(tire);
  }

  public static decodeTireString(tire: string): string[] {
    if (!Utils.isValidTireString(tire)) return [];

    const match = tire.match(/^.{0,3}(\d{3})\/?(\d{2})?\D{0,3}(\d{2})\D/)!;

    const width = match[1];
    const height = match[2];
    const diameter = match[3];

    return [width, height, diameter];
  }

  public static isInStock(stockItem: IStockItem | undefined) {
    if (stockItem == undefined) {
      return false;
    }
    if (stockItem.quantity == "e") {
      return true;
    }
    if (stockItem.quantity == "l" || stockItem.quantity == "t") {
      return false;
    }
    return parseInt(stockItem.quantity) > 0;
  }
}
