import { AppConfig } from "app-config";
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { BaseService } from "../base-service";
import { Router } from "aurelia-router";
import { ICart } from "domain/Cart/ICart";

export const log = LogManager.getLogger("app.service.cart-product");

@autoinject
export class CartProductService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private endPoint = "CartProduct"
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  putExistingProduct(entity: {
    cartProductId: number;
    foundBy: string;
    code: string;
    quantity: number;
    warehouseId: number;
    externalWarehouseId: number;
    transportId: number;
  }): Promise<ICart> {
    return super.put(entity, `/${entity.cartProductId}`);
  }
}
