import { Router } from 'aurelia-router';

import { AppConfig } from 'app-config';
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { ICart } from "domain/Cart/ICart";
import { BaseService } from '../base-service';

export const log = LogManager.getLogger('app.service.cart');

@autoinject
export class CartService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private endPoint = 'Cart'
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  fetchCurrentFull(): Promise<ICart> {
    return super.fetch<ICart>("/Full");
  }

  fetchCurrent(): Promise<ICart> {
    return super.fetch<ICart>();
  }

  clearCart(): Promise<Response> {
    return super.delete(0, " ");
  }

  placeOrder(comment: string): Promise<Response> {
    return super.put(comment);
  }
}
