import { Router } from 'aurelia-router';
import { AppConfig } from 'app-config';
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { BaseService } from '../base-service';

export const log = LogManager.getLogger('app.service.order');

@autoinject
export class TransportService extends BaseService {
  constructor(
    httpClient: HttpClient,
    appConfig: AppConfig,
    private router: Router,
    endPoint = 'Transport'
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  getAllowedTransportOptions(): Promise<Map<string, string>> {
    return super.fetch<object>().then(result =>
      result ? new Map(Object.entries(result)) : new Map<string, string>());
  }

  getAllTransportOptions(): Promise<Map<number, Map<number, string>>> {
    return super.fetch<object>("/All").then(result => this.parseTransportOptions(result));
  }

  getWarehouseTransportOptions(warehouseId: number): Promise<Map<string, string>> {
    return super.fetch<object>(`/${warehouseId}`).then(result => new Map(Object.entries(result)));
  }

  parseTransportOptions(result: object) {
    const map = new Map<number, Map<number, string>>();
    for (const [warehouseId, transportOptions] of Object.entries(result)) {
      const optionsMap = new Map<number, string>(Object.entries(transportOptions).map(([key, value]) => [parseInt(key), value as string]));
      map.set(parseInt(warehouseId), optionsMap);
    }
    return map;
  }
}
