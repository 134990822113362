import { Router } from 'aurelia-router';
import { BaseService } from '../base-service';
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { AppConfig } from "app-config";

export const log = LogManager.getLogger('app.service.identity');

@autoinject
export class IdentityService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private endPoint = "Identity"
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  login(email: string, password: string): Promise<string> {
    return super.post<string>({
      Email: email,
      Password: password
    }, "/Login");
  }

  changePassword(oldPassword: string, newPassword: string): Promise<Response> {
    return super.post({
      OldPassword: oldPassword,
      NewPassword: newPassword
    }, "/ChangePassword");
  }

  resetPassword(token: string, email: string, password: string): Promise<string> {
    return super.post<string>({
      Token: token,
      Email: email,
      Password: password
    }, "/ResetPassword");
  }

  forgotPassword(email: string): Promise<void> {
    return super.post({
      Email: email
    }, "/ForgotPassword");
  }

  logout(): void {
    this.appConfig.removeToken();
  }

  adminLogin(email: string, token: string): Promise<string> {
    return super.post<string>({
      Email: email,
      Token: token
    }, "/AdminLogin");
  }
}
