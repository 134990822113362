/* eslint-disable @typescript-eslint/ban-types */
import { ServiceResponseError } from 'utils/classes/error/ServiceResponseError';
import { LogManager } from "aurelia-framework";
import { HttpClient, json } from 'aurelia-fetch-client';
import { IBaseEntity } from "domain/Base/IBaseEntity";
import { AppConfig } from "app-config";
import { TKError } from 'utils/enums/TKError';
import { Router } from 'aurelia-router';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

export const log = LogManager.getLogger('app.services.base');

export class BaseService {
  serviceHttpClient: HttpClient;
  serviceAppConfig: AppConfig;
  serviceEndpoint: string;

  constructor(
    httpClient: HttpClient,
    appConfig: AppConfig,
    endPoint: string,
    router: Router,
  ) {
    this.serviceHttpClient = httpClient.configure(config => {
      // We can only configure stuff that applies to every service, logged in or not
      config
        .withBaseUrl(`${appConfig.apiUrl}/`)
        .withDefaults({
          credentials: 'include',
          headers: {
            cache: 'no-store'
          }
        })
        .withInterceptor({
          request(request) {
            if (appConfig.jwt && appConfig.isTokenExpired())
              router.navigateToRoute('userLogout', { error: TKError.sessionExpired });
              
            return request;
          },
          response(response) {
            if (response.status == 401)
              router.navigateToRoute('userLogout', { error: TKError.sessionExpired });
              
            return response;
          }
        });
    });
    this.serviceAppConfig = appConfig;
    this.serviceEndpoint = endPoint;
  }

  fetchOne<TEntity>(id: number, finalEndpoint = ""): Promise<TEntity> {
    if (finalEndpoint == "")
      finalEndpoint = `/${id}`;

    return this.fetch<TEntity>(finalEndpoint);
  }

  fetchAll<TEntity>(finalEndpoint = ""): Promise<TEntity[]> {
    return this.fetch<TEntity[]>(finalEndpoint);
  }

  fetch<TEntity>(finalEndpoint = ""): Promise<TEntity> {
    const url = `${this.serviceEndpoint}${finalEndpoint}`;
    return new Promise((res, rej) => this.serviceHttpClient.fetch(url, this._init())
      .then(this._res).then(json => this._suc(json, res)).catch(reason => this._err(reason, rej)));
  }


  post<TEntity>(entity: any, finalEndpoint = ""): Promise<TEntity> {
    const url = `${this.serviceEndpoint}${finalEndpoint}`;
    return new Promise((res, rej) => this.serviceHttpClient.post(url, json(entity), this._init())
      .then(this._res).then(json => this._suc(json, res)).catch(reason => this._err(reason, rej)));
  }


  putEntity<TEntity extends IBaseEntity>(entity: TEntity, finalEndpoint = ""): Promise<Response> {
    if (finalEndpoint == "")
      finalEndpoint = `/${entity.id}`;

    return this.put(entity, finalEndpoint);
  }

  put<TEntity>(data: any = null, finalEndpoint = ""): Promise<TEntity> {
    const url = `${this.serviceEndpoint}${finalEndpoint}`;
    return new Promise((res, rej) => this.serviceHttpClient.put(url, json(data), this._init())
      .then(this._res).then(json => this._suc(json, res)).catch(reason => this._err(reason, rej)));
  }


  delete(id: number, finalEndpoint = ""): Promise<Response> {
    if (finalEndpoint == "")
      finalEndpoint = `/${id}`;

    const url = `${this.serviceEndpoint}${finalEndpoint}`;
    return new Promise((res, rej) => this.serviceHttpClient.delete(url, null, this._init())
      .then(this._res).then(json => this._suc(json, res)).catch(reason => this._err(reason, rej)));
  }



  private _init() {
    return {
      headers: { Authorization: `Bearer ${this.serviceAppConfig.jwt}` }
    };
  }

  private _res(response: Response) {
    if (response.status >= 200 && response.status < 300) {
      if (response.status != 204)
        return response.json();
    } else {
      throw new ServiceResponseError(response);
    }
  }

  private _suc(json: string, res: Function) {
    res(json);
  }

  private _err(reason: Error, rej: Function) {
    rej(reason);
  }
}
