import { autoinject } from "aurelia-framework";
import { NavigationInstruction, Redirect } from "aurelia-router";

// singleton by virtue of aurelia's default DI
@autoinject
export class NotAuthorizedRedirect {
  private navigationInstruction: NavigationInstruction | null;

  public notAuthorized(from: NavigationInstruction) {
    this.navigationInstruction = from;
    return new Redirect("user/login");
  }

  public tryReturnTo(): string {
    if (!this.navigationInstruction) return "";

    const routeName = this.navigationInstruction.config.name;
    if (
      routeName === undefined ||
      routeName == "userLogin" ||
      routeName == "userLogout"
    )
      return "";

    const uri = this.navigationInstruction.router.generate(
      routeName,
      Object.assign(
        this.navigationInstruction.params,
        this.navigationInstruction.queryParams
      ),
      { replace: true }
    );

    this.navigationInstruction = null;
    return uri;
  }
}
