import { Router } from 'aurelia-router';
import { BaseService } from '../base-service';
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { AppConfig } from "app-config";
import { ISettings } from 'domain/Settings/ISettings';

export const log = LogManager.getLogger('app.service.user');

@autoinject
export class UserService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private endPoint = "User"
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  getSettings(): Promise<ISettings> {
    return super.fetch<ISettings>("/Settings");
  }

  setSettings(settings: ISettings): Promise<Response> {
    return super.put(settings, "/Settings");
  }

  updateSettings(): Promise<Response> {
    const settings: ISettings = {
      userSettings: this.appConfig.userSettings,
      contractSettings: this.appConfig.contractSettings
    };
    return super.put(settings, "/Settings");
  }
}
