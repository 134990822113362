export enum TKEvent {
  cartProductsChanged = "cartProducts:changed",
  cartProductsWarehouseChanged = "cartProducts:changed:warehouse",
  updateCartTotal = "updateCartTotal",

  returnSent = "return:sent",

  userSettingsLoaded = "user:settings:loaded",
  warehousesLoaded = "warehouses:loaded",
  transportOptionsLoaded = "transport-options:loaded",

  localeChanged = "locale:changed",
  chosenFiltersChanged = "chosenFilters:changed",
}
