export enum SearchOrder {
  Default = 0,
  Code = 1,
  Name = 2,
  EAN = 3,
  Brand = 4,
  InPackage = 5,
  Stock = 6,
  Price = 7,
  InStock = 8
}
