import { Router } from 'aurelia-router';
import { IWarehouse } from 'domain/Client/IWarehouse';
import { BaseService } from '../base-service';
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { AppConfig } from "app-config";

export const log = LogManager.getLogger('app.service.client');

@autoinject
export class WarehouseService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private endPoint = "Warehouse"
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  getWarehouses(): Promise<IWarehouse[]> {
    return super.fetchAll<IWarehouse>();
  }
}
