import { Router } from 'aurelia-router';
import { SearchType } from 'utils/enums/SearchType';
import { IProduct } from 'domain/Product/IProduct';
import { IResult } from 'domain/IResult';
import { BaseService } from 'services/base-service';
import { AppConfig } from 'app-config';
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { SearchOrder } from 'utils/enums/SearchOrder';

export const log = LogManager.getLogger('app.service.search');

@autoinject
export class SearchService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private endPoint = 'Search'
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  fetchResult(query: string, page: number, type: SearchType, orderBy: SearchOrder, orderReversed: boolean): Promise<IResult<IProduct>> {
    const itemsInPage = this.appConfig.userSettings.itemsInProductPage;
    return super.fetch<IResult<IProduct>>(`/${query}/${page}/${itemsInPage}/${type}/${orderBy}/${orderReversed}`);
  }
}
