import { Router } from 'aurelia-router';
import { IResult } from 'domain/IResult';
import { ICart } from 'domain/Cart/ICart';
import { AppConfig } from 'app-config';
import { LogManager, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { BaseService } from '../base-service';

export const log = LogManager.getLogger('app.service.return');

@autoinject
export class ReturnService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router,
    private endPoint = 'Return'
  ) {
    super(httpClient, appConfig, endPoint, router);
  }

  returnProduct(entity: {
    invoiceId: number;
    rvsOrderId: number;
    code: string;
    amount: number;
  }): Promise<Response> {
    return super.post(entity);
  }

  fetchReturns(page: number): Promise<IResult<ICart>> {
    const itemsInPage = this.appConfig.userSettings.itemsInReturnPage;

    return super.fetch<IResult<ICart>>(`/List/${page}/${itemsInPage}`);
  }

  fetchCurrent(): Promise<ICart> {
    return super.fetch<ICart>();
  }

  clearReturnCart(): Promise<Response> {
    return super.delete(0, " ");
  }

  sendReturn(comment: string): Promise<Response> {
    return super.post(comment, "/Send");
  }

  fetchReturnText(id: number): Promise<string> {
    return super.fetch<string>("/Text/" + id);
  }
}
