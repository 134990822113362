import { inject } from 'aurelia-framework';
import { Tooltip } from 'bootstrap';

@inject(Element)
export class TooltipCustomAttribute {
  tooltip: Tooltip
  value: string;

  constructor(private element: Element) {}

  valueChanged(newValue: any) {
    this.element.setAttribute('title', newValue);

    this.tooltip = new Tooltip(this.element);
  }

  bind() {
    this.element.setAttribute('data-bs-toggle', 'tooltip');
    this.element.setAttribute('data-bs-placement', 'top');
    this.element.setAttribute('data-bs-html', 'true');
    this.element.setAttribute('title', this.value);

    this.tooltip = new Tooltip(this.element);
  }

  unbind() {
    this.tooltip.dispose();
  }
}
